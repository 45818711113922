import React, { useState } from "react";
import { apiUrl } from "../assets/utils/env";
import axios from "axios";

const options = {
  weekday: "short",
  day: "numeric",
  month: "short",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZone: "Europe/London",
};

function SaleRow({ row, index, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleDeleteClick = () => {
    onDelete(index);
  };

  const handleSaveClick = () => {
    // Add logic to save the edited row, if needed
    setIsEditing(false);
    handleSave();
  };
  const handleCancelClick = () => {
    // Add logic to cancel the edited row
    setIsEditing(false);
  };

  const renderButtons = () => {
    if (isEditing) {
      return (
        <>
          <button
            className="btn btn-danger text-white"
            onClick={handleCancelClick}
          >
            Cancel
          </button>

          <button
            className="btn btn-danger text-white"
            onClick={handleSaveClick}
          >
            Save
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            className="btn btn-danger text-white mx-2"
            onClick={handleEditClick}
          >
            Edit
          </button>
          <button
            className="btn btn-danger text-white "
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        </>
      );
    }
  };

  const [orderStatus, setOrderStatus] = React.useState(row.status);

  const statusOptions = ["cancelled", "pending", "completed"];
  const token = localStorage.getItem("token");

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setOrderStatus(value);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .patch(`${apiUrl}/api/v1/order/${row._id}`, { status: value }, config)
      .then((res) => {
        console.log(res.data);
        alert("Changed order status");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const typeOfOrderOptions = ["eatin", "takeaway", "delivery"];
  const [typeOfOrderState, setTypeOfOrderState] = React.useState(
    row.typeOfOrder
  );
  const handleTypeOfOrderChange = (e) => {
    const { value } = e.target;
    setTypeOfOrderState(value);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .patch(
        `${apiUrl}/api/v1/order/${row._id}`,
        { typeOfOrder: value },
        config
      )
      .then((res) => {
        console.log(res.data);
        alert("Changed order type of order!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const paymentTypesOptions = [
    "Cash",
    "Card",
    "Online",
    "JustEat",
    "Deliveroo",
    "UberEats",
  ];
  const [paymentTypeState, setPaymentTypeState] = React.useState(
    row.paymentType
  );
  const handlePaymentTypeChange = (e) => {
    const { value } = e.target;
    setPaymentTypeState(value);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .patch(
        `${apiUrl}/api/v1/order/${row._id}`,
        { paymentType: value },
        config
      )
      .then((res) => {
        console.log(res.data);
        alert("Changed payment type of order!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [totalPrice, setTotalPrice] = React.useState(row.totalPrice);
  const [clientPay, setClientPay] = React.useState(row.clientPay);

  const handleSave = () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .patch(
        `${apiUrl}/api/v1/order/${row._id}`,
        { totalPrice, clientPay },
        config
      )
      .then((res) => {
        console.log(res.data);
        alert("Updated Order!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <tr style={{ textAlign: "center" }}>
      <td className="w-10px align-middle">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`product${index}`}
          />
          <label className="form-check-label"></label>
        </div>
      </td>
      <td className="align-middle">
        <a href>00{index + 1}</a>
      </td>
      <td className="align-middle">
        {new Date(row.createdDate).toLocaleString("en-GB", options)}
      </td>
      <td
        className="align-middle"
        style={{ maxWidth: "200px", overflow: "hidden" }}
      >
        {isEditing ? (
          <input
            type="text"
            value={row.commodityList.map((comm) => `${comm.name},`)}
            onChange={(e) => console.log(e.target.value)}
          />
        ) : (
          row.commodityList.map((comm) => `${comm.name},`)
        )}
      </td>
      {/* staff */}
      <td className="align-middle">{row.workerId.name}</td>
      {/* status */}
      <td className="align-middle">
        {isEditing ? (
          <select value={orderStatus} onChange={handleStatusChange}>
            {statusOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <span>{row.status}</span>
        )}

        {/* {isEditing ? (
          <input
            type="text"
            value={row.status}
            onChange={(e) => console.log(e.target.value)}
          
          />
          
        ) : (
          row.status
        )} */}
      </td>
      <td className="align-middle">
        {isEditing ? (
          <select value={paymentTypeState} onChange={handlePaymentTypeChange}>
            {paymentTypesOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <span>{row.paymentType}</span>
        )}
        {/* {isEditing ? (
          <input
            type="text"
            value={row.paymentType}
            onChange={(e) => console.log(e.target.value)}
          />
        ) : (
          row.paymentType
        )} */}
      </td>
      <td className="align-middle">
        {isEditing ? (
          <select value={typeOfOrderState} onChange={handleTypeOfOrderChange}>
            {typeOfOrderOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : (
          <span>{row.typeOfOrder}</span>
        )}
      </td>
      <td className="align-middle">
        {isEditing ? (
          <input
            type="text"
            value={totalPrice}
            onChange={(e) => setTotalPrice(Number(e.target.value))}
          />
        ) : (
          <span>{row.totalPrice}</span>
        )}
      </td>
      <td className="align-middle">
        £{Math.round(((row.totalPrice * 20) / 120) * 100) / 100}?
      </td>
      <td className="align-middle">£0</td>
      <td className="align-middle">
        {isEditing ? (
          <input
            type="text"
            value={clientPay}
            onChange={(e) => setClientPay(Number(e.target.value))}
          />
        ) : (
          `£${row.clientPay}`
        )}
      </td>
      <td className="align-middle">{renderButtons()}</td>
    </tr>
  );
}

export default SaleRow;
