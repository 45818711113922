import * as React from "react";

// import image from "../../test_image.png";
import "./componentStyles/PrintComponentStyles.css";
import "./componentStyles/Print.css"
export class ComponentToPrint2 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.list = this.props.commodityList;
        this.state = { checked: false };
    }
    canvasEl;
    componentDidMount() {
    }

  
    handleCheckboxOnChange = () =>
        this.setState({ checked: !this.state.checked });
    render() {
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const time = `${hours % 12 || 12}:${minutes} ${hours < 12 ? 'am' : 'pm'}`;
            return `${time} ${day} ${month}, ${year}`;
        };

        console.log("this.props.commodityList=====", this.props.commodityList);
        let li = this.props.commodityList; 
        let lii = li.productInfo
        return (
            <div className="ticket hidden" >
                <div style={{ width: "120%", border: "1px solid black", marginRight:"20px" }} class="bg-light">
                    <div style={{ width: "90%" }} class="container">    
                        <div class="row" >
                            <div class="text-start bg-dark ">
                                <center><h4 class="text-light mt-1" >Foodelicious</h4></center>
                            </div>
                        </div>

                        <div class="row mt-3" >

                            <div class="col-6">
                                <p style={{ fontSize: "14px" }} class="table-name">Name:</p>
                            </div>
                            <div class="col-6 text-end">
                                <p style={{ fontSize: "14px" }}>{li.customerName}</p>
                            </div>


                            <div class="col-6">
                                <p style={{ fontSize: "14px" }} class="table-name">Order ID:</p>
                            </div>
                            <div class="col-6 text-end">
                                <p style={{ fontSize: "14px" }}>{li.orderNumber}</p>
                            </div>

                            <div class="col-6">
                                <p class="table-name">Date:Time</p>
                            </div>
                            <div class="col-6 text-end">
                                <p style={{ fontSize: "14px" }}>{formatDate(li.orderPlaceDate)}</p>
                            </div>

                            <div class="col-6">
                                <p style={{ fontSize: "14px" }} class="table-name">Address</p>
                            </div>
                            <div class="col-6 text-end">
                                <p style={{ fontSize: "14px" }}>{li.customerAddress}</p>
                            </div>
                            
                            <div class="col-6">
                                <p style={{ fontSize: "14px" }} class="table-name">Phone:</p>
                            </div>
                            <div class="col-6 text-end">
                                <p style={{ fontSize: "14px" }}>{li.customerPhone}</p>
                            </div>



                        </div>
                         
                        <hr class="mt-2" /> 
                                <hr class="mt-2" />  
                                {li && li.productInfo && li.productInfo.length >= 0 && (
                                    <> 
                                                {li.productInfo[0].products.map((data, index) => (
                                                    <>
                                                        <div class="row" key={index}>
                                                            <div class="col-9">
                                                                <p class="table-name-small">
                                                                    <p style={{ fontSize: "14px" }}>{data.quantity} {" X "}{data.name}</p>
                                                                </p>

                                                                <div>
                                                                    <ul style={{ marginLeft: "5px" }}>
                                                                        {data.options.map((da, index) => (
                                                                            <span key={index}>
                                                                                <li  style={{ fontSize: "13px" }}>{da.shortName} {" "}:{" "}{da.type}</li>
                                                                            </span>
                                                                        ))}

                                                                    </ul>
                                                                </div>
                                                            </div>



                                                            <div class="col-3 text-end">
                                                                <p class="" style={{ fontSize: "13px" }}>
                                                                    {(data.productPrice * data.quantity).toFixed(2)}

                                                                </p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                ))}

                                                <div class="row">
                                                    <div class="col-6">
                                        <p style={{ fontSize: "14px" }}>Subtotal</p>
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <p>{li.productInfo[0].totalPrice.toFixed(2)}</p>
                                                    </div>
                                                    {li.productInfo[0].distance < 0.5 && li?.productInfo[0].totalPrice > 20 ? (
                                                        <>
                                                            <div class="col-6">
                                                <p style={{ fontSize: "14px" }}>Delivery Charges</p>
                                                            </div>
                                                            <div class="col-6 text-end">
                                                <p style={{ fontSize: "14px" }}>(£0)</p>
                                                            </div>
                                                            <div class="col-6">
                                                <p style={{ fontSize: "14px" }} class="table-name">Amount paid</p>
                                                            </div>
                                                            <div class="col-6 text-end">
                                                <p style={{ fontSize: "14px" }}>£{li.productInfo[0].totalPrice.toFixed(2)}</p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div class="col-6">
                                                    <p style={{ fontSize: "14px" }}>Delivery Charges</p>
                                                            </div>
                                                            <div class="col-6 text-end">
                                                    <p style={{ fontSize: "14px" }}>£2.49</p>
                                                            </div>
                                                            <div class="col-6">
                                                    <p style={{ fontSize: "14px" }} class="table-name">Amount paid</p>
                                                            </div>
                                                            <div class="col-6 text-end">
                                                    <p style={{ fontSize: "14px" }}>£{(li.productInfo[0].totalPrice + 2.49).toFixed(2)}</p>
                                                            </div>
                                                        </>
                                                    )}



                                                    <hr />

                                                    <div class="col-12 text-center">
                                        <p style={{ fontSize: "12px" }}>Thank You for ordering from Food Relicious</p>
                                                    </div>



                                                </div> 
                                    </>
                                )} 
                            </div>
                        </div> 
                    </div>
               
        );
    }
}
export const FunctionalComponentToPrint2 = React.forwardRef((props, ref) => {
    return <ComponentToPrint2 ref={ref} text={props.text} />;
});