import * as React from "react";

// import image from "../../test_image.png";
import "./componentStyles/PrintComponentStyles.css";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props); 
    this.list = this.props.commodityList;
    this.state = { checked: false };
  } 
  canvasEl; 
  componentDidMount() { 
  }

  handleCheckboxOnChange = () =>
    this.setState({ checked: !this.state.checked }); 
  render() { 
    console.log(this.props.commodityList); 
    return (
      <div className="ticket hidden"> 
        <p className="centered">
          FOODELICIOUS
          <br />
          80 EAST STREET
          <br />
          BEDMINSTER, BS3 4EY
          <br />
          TEL: 01170000000
          <br />
          VAT NO: ________
        </p>
        <table>
          <thead>
            <tr>
              <th className="quantity">Q.</th>
              <th className="description">Description</th>
              <th className="price">$$</th>
            </tr>
          </thead>
          <tbody>
            {this.props.commodityList?.map((li) => {
              return (
                <tr className="commodity-row">
                  <td className="quantity">{li.unit}.00</td>
                  <td className="description">
                    {li.name}
                    {li.options?.map((opt) => {
                      return (
                        <>
                          <br /> - {opt.shortName}: {opt.type} [${opt.price}]
                        </>
                      );
                    })} 
                  </td>
                  <td className="price">${li.productPrice}</td>
                </tr>
              );
            })}
           
          </tbody>
        </table>
        <p className="centered">
          Thanks for your order!
          <br />
          Order online for exclusive prices & discounts.
          <br />
          www.foodeliciousbristol.co.uk
        </p>
      </div>
    );
  }
} 
export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  return <ComponentToPrint ref={ref} text={props.text} />;
});