import MenuBar from "./menu";
import Navbar from "./navbar";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "./assets/utils/env";
import { useEffect, useState } from "react";
import "./home.css";
import FunctionalComponent2 from "./components/Print2";

function HomePage({ setIsLoggedIn, worker }) {
  // const navigate = useNavigate();


  const [orders, setOrders] = useState([]);
  const [completeOrdersID, setCompleteOrdersID] = useState("");
  const [ViewOrder, setViewOrder] = useState({});

  const completeOrder = (id) => {
    // console.log("id",id);
    setCompleteOrdersID(id);
  }

 const printDiv = (elementId) => {
    const a = document.getElementById('printing-css').value;
    const b = document.getElementById(elementId).innerHTML;
    const printFrame = document.createElement('iframe');
    document.body.appendChild(printFrame);
    printFrame.contentWindow.document.write('<style>' + a + '</style>' + b);
    printFrame.contentWindow.document.close();
    printFrame.contentWindow.print();
    document.body.removeChild(printFrame);
  };


  const PrintViewOrder = () => {
    window.print();
    console.log("ajncjhi=====", ViewOrder.productInfo[0].products)
  }

  useEffect(()=>{
    getAllOrderNotification();
  },[]);


  useEffect(() => {
    const interval = setInterval(() => {
      getAllOrderNotification();
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  const getAllOrderNotification = async () => {
    axios.get(`${apiUrl}/api/v1/siteorder/getAllOrderNotification`)
      .then((res) => {
        // console.log("abc====", res.data.siteorders);
        setOrders(res.data.siteorders)
      })
      .catch((err) => console.log("error----->>>", err));
  }
   



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours % 12 || 12}:${minutes} ${hours < 12 ? 'am' : 'pm'}`;
    return `${time} ${day} ${month}, ${year}`;
  };

  const formatDatePrint = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours % 12 || 12}:${minutes} ${hours < 12 ? 'am' : 'pm'}`;
    return `${time} `;
  };

  const formatTimePrint = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours % 12 || 12}:${minutes} ${hours < 12 ? 'am' : 'pm'}`;
    return ` ${day} ${month}, ${year}`;
  };
  const confirmComplete = () => {
    axios.patch(`${apiUrl}/api/v1/siteorder/orderCompleteStatus/${completeOrdersID}`)
      .then((res) => console.log("abc", res.data))
      .catch((err) => console.log(err));
  }

  return (
    <div id="app" class="app">
      <Navbar setIsLoggedIn={setIsLoggedIn} worker={worker} />
      <MenuBar />
      <button
        class="app-sidebar-mobile-backdrop"
        data-toggle-target=".app"
        data-toggle-class="app-sidebar-mobile-toggled"
      ></button>

      <div id="content" class="app-content">
        <div class="row">
          <div class="col-xl-3 col-lg-6 ">
            <div class="card mb-3 " style={{ border: "none" }}>
              <div class="card-body bg-white rounded">
                <div class="d-flex fw-bold small mb-3 ">
                  <span class="flex-grow-1">SITE VISITORS</span>
                  <a
                    href
                    data-toggle="card-expand"
                    class="text-dark text-opacity-50 text-decoration-none"
                  >
                    <i class="bi bi-fullscreen"></i>
                  </a>
                </div>

                <div class="row align-items-center mb-2">
                  <div class="col-7">
                    <h3 class="mb-0" style={{ color: "grey" }}>
                      3
                    </h3>
                  </div>
                  <div class="col-5">
                    <div
                      class="mt-n2"
                      data-render="apexchart"
                      data-type="bar"
                      data-title="Visitors"
                      data-height="30"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-6">
            <div class="card mb-3 " style={{ border: "none" }}>
              <div class="card-body bg-white rounded">
                <div class="d-flex fw-bold small mb-3">
                  <span class="flex-grow-1">STORE SALES</span>
                  <a
                    href
                    data-toggle="card-expand"
                    class="text-dark text-opacity-50 text-decoration-none"
                  >
                    <i class="bi bi-fullscreen"></i>
                  </a>
                </div>

                <div class="row align-items-center mb-2">
                  <div class="col-7">
                    <h3 class="mb-0" style={{ color: "grey" }}>
                      £423
                    </h3>
                  </div>
                  <div class="col-5">
                    <div
                      class="mt-n2"
                      data-render="apexchart"
                      data-type="line"
                      data-title="Visitors"
                      data-height="30"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-6">
            <div class="card mb-3 " style={{ border: "none" }}>
              <div class="card-body bg-white rounded">
                <div class="d-flex fw-bold small mb-3">
                  <span class="flex-grow-1">NEW MEMBERS</span>
                  <a
                    href
                    data-toggle="card-expand"
                    class="text-dark text-opacity-50 text-decoration-none"
                  >
                    <i class="bi bi-fullscreen"></i>
                  </a>
                </div>

                <div class="row align-items-center mb-2">
                  <div class="col-7">
                    <h3 class="mb-0" style={{ color: "grey" }}>
                      100
                    </h3>
                  </div>
                  <div class="col-5">
                    <div
                      class="mt-n3 mb-n2"
                      data-render="apexchart"
                      data-type="pie"
                      data-title="Visitors"
                      data-height="45"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card mb-3 " style={{ border: "none" }}>
              <div class="card-body bg-white rounded">
                <div class="d-flex fw-bold small mb-3">
                  <span class="flex-grow-1">TOP PRODUCTS</span>
                  <a
                    href
                    data-toggle="card-expand"
                    class="text-dark text-opacity-50 text-decoration-none"
                  >
                    <i class="bi bi-fullscreen"></i>
                  </a>
                </div>

                <div class="table-responsive">
                  <table class="w-100 mb-0 small align-middle text-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="position-relative">
                              <div
                                class="bg-center bg-cover bg-no-repeat w-80px h-60px"
                                style={{
                                  backgroundImage:
                                    "url(./public/assets/img/pos/bbq.jpg)",
                                }}
                              ></div>
                              <div class="position-absolute top-0 start-0">
                                <span class="badge bg-black bg-opacity-50 rounded-0 d-flex align-items-center justify-content-center w-20px h-20px">
                                  1
                                </span>
                              </div>
                            </div>
                            <div class="flex-1 ps-3" x>
                              <div class="mb-1">
                                <small class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-white bg-opacity-25 text-dark text-opacity-75 pt-5px">
                                  SKU000001
                                </small>
                              </div>
                              <div class="text-dark fw-500">
                                Pepporini Pizza
                              </div>
                              10£
                            </div>
                          </div>
                        </td>
                        <td>
                          <table style={{ lineHeight: "1px" }}>
                            <tr>
                              <td class="pe-3">QTY:</td>
                              <td class="text-dark text-opacity-75 fw-500">
                                50
                              </td>
                            </tr>
                            <tr>
                              <td class="pe-3">REVENUE:</td>
                              <td class="text-dark text-opacity-75 fw-500">
                                £0
                              </td>
                            </tr>
                            <tr>
                              <td class="pe-3 text-nowrap">PROFIT:</td>
                              <td class="text-dark text-opacity-75 fw-500">
                                £0
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <a href class="text-decoration-none text-dark">
                            <i class="bi bi-search"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card mb-3 " style={{ border: "none" }}>
              <div class="card-body bg-white rounded">
                <div class="d-flex fw-bold small mb-3">
                  <span class="flex-grow-1">ACTIVITY LOG</span>
                  <a
                    href
                    data-toggle="card-expand"
                    class="text-dark text-opacity-50 text-decoration-none"
                  >
                    <i class="bi bi-fullscreen"></i>
                  </a>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-borderless mb-2px small text-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <span
                            class="d-flex align-items-center"
                            style={{ color: "black" }}
                          >
                            <i class="bi bi-circle-fill fs-6px text-theme me-2"></i>
                            You have sold an item - £12
                          </span>
                        </td>
                        <td>
                          <small style={{ color: "black" }}>just now</small>
                        </td>

                        <td>
                          <a href class="text-decoration-none text-dark">
                            <i class="bi bi-search"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="col-xl-6">
            <div class="card mb-3 " style={{ border: "none" }}>
              <div class="card-body bg-white rounded">
                <div class="d-flex fw-bold small mb-3">
                  <span class="flex-grow-1">Website Orders</span>
                  <a
                    href
                    data-toggle="card-expand"
                    class="text-dark text-opacity-50 text-decoration-none"
                  >
                    <i class="bi bi-fullscreen"></i>
                  </a>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-borderless mb-2px small text-nowrap">
                    <tbody>

                      {orders ? orders.sort((a, b) => new Date(b.orderPlaceDate) - new Date(a.orderPlaceDate)).map((order, index) => (
                        <tr key={index}>
                          <td>
                            <span className="d-flex align-items-center" style={{ color: "black" }}>
                              <i className="bi bi-circle-fill fs-6px text-theme me-2"></i>
                              {order.customerName}
                            </span>
                          </td>
                          <td>
                            <small style={{ color: "black" }}>{formatDate(order.orderPlaceDate)}</small>
                          </td>

                          <td>
                              {order.readyStatus === "active" ? (
                                <>
                                <button style={{ backgroundColor: "#ff791e", border: "2px solid #ff791e", borderRadius: "5px", marginBottom: "5px" }}>
                                  <span style={{ color: "white", marginTop: "5px", fontSize: "13px" }}>&nbsp;&nbsp;Active&nbsp;&nbsp;</span>
                                </button>
                                </>
                              ) : (
                                <>
                                  <button style={{ backgroundColor: "#ff1616", border: "2px solid #ff1616", borderRadius: "5px", marginBottom: "5px" }}>
                                  <span style={{ color: "white", marginTop: "5px", fontSize: "13px" }}>&nbsp;&nbsp;Non-Active&nbsp;&nbsp;</span>
                              </button>
                                </>
                                
                              )}
                          </td>
                          <td>
                            <button onClick={() => completeOrder(order._id)} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ backgroundColor: "#0d9340", border: "2px solid #0d9340", borderRadius: "5px", marginBottom: "5px" }}>

                              <span style={{ color: "white", marginTop: "5px", fontSize: "13px" }}>&nbsp;&nbsp;Complete&nbsp;&nbsp;</span>
                            </button>
                          </td>
                          
                          <td>
                            <button onClick={() => {
                              setViewOrder(order); 
                            }} data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ backgroundColor: "#b0a6a0", border: "2px solid #b0a6a0", borderRadius: "5px", marginBottom: "5px" }}>
                              <span style={{ color: "white", marginTop: "5px", fontSize: "13px" }}>&nbsp;&nbsp;View&nbsp;&nbsp;</span>
                            </button>


                          </td>
                        </tr>
                      )) : null}

 
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      <a href data-toggle="scroll-to-top" class="btn-scroll-top fade">
        <i class="fa fa-arrow-up"></i>
      </a>

      <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style={{ borderRadius: "10px" }}>
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Confirm Complete</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Do you want to confirm complete this order?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary text-white" data-bs-dismiss="modal">Close</button>
              <button type="button" onClick={confirmComplete} class="btn btn-primary text-white" data-bs-dismiss="modal">Confirm</button>
            </div>
          </div>
        </div>
      </div>

 
      <div class="modal fade " id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" style={{ borderRadius: "10px"}}>
          <div class="modal-content" id="printableModalContent"> 
            <div class="modal-body" >
          
              <div className="container ">
                <div className="row p-3 text-center" style={{ background: '#e47c35' }}>
                  <div className="col-12">
                    <h1 className="text-white font-weight-bold">Restaurant </h1>
                  </div>
                </div>
                <table className="table mt-3" style={{ borderCollapse: 'collapse' }}>
                  <tbody>
                    <tr>
                      <td style={{ border: 'none', padding: '5px' }}>Name</td>
                      <td style={{ border: 'none', padding: '5px' }}>{ViewOrder.customerName}</td>
                    </tr>

                    <tr>
                      <td style={{ border: 'none', padding: '5px' }}>Phone Number</td>
                      <td style={{ border: 'none', padding: '5px' }}>{ViewOrder.customerPhone}</td>
                    </tr>
                    <tr>
                      <td style={{ border: 'none', padding: '5px' }}>Order ID #</td>
                      <td style={{ border: 'none', padding: '5px' }}>{ViewOrder.orderNumber}</td>
                    </tr>
                    <tr>
                      <td style={{ border: 'none', padding: '5px' }}>Date & Time</td>
                      <td style={{ border: 'none', padding: '5px' }}>{formatDate(ViewOrder.orderPlaceDate)}</td>
                    </tr>
                    <tr>
                      <td style={{ border: 'none', padding: '5px' }}>Address</td>
                      <td style={{ border: 'none', padding: '5px' }}>{ViewOrder.customerAddress}</td>
                    </tr>
                  

                  </tbody>
                </table>
                <hr className="new" />
                <table className="table table-striped" style={{ borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ padding: '5px' }}>#</th>
                      <th scope="col" style={{ padding: '5px' }}>Food Name</th>
                      {/* <th scope="col" style={{ padding: '5px' }}>Used Items</th> */}
                      <th scope="col" style={{ padding: '5px' }}>Quantity</th>
                      <th scope="col" style={{ padding: '5px' }}>Price per Item</th>
                      <th scope="col" style={{ padding: '5px' }}>Total Price</th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "black" }}>
                    {ViewOrder && ViewOrder.productInfo && ViewOrder.productInfo[0] && ViewOrder.productInfo[0].products && ViewOrder.productInfo[0].products.map((data, index) => (
                      <>
                        <tr style={{ backgroundColor: "grey", color: "black" }}>
                          <th scope="row" style={{ padding: '5px' }}>{index + 1}</th>
                          <td style={{ padding: '5px' }}>
                            {data.name}
                            <ul>
                              <><small >
                                {data.options && data.options.map((da, i) => (
                                  <span key={i}>
                                    <li>
                                      {da.shortName}:{da.type}</li>
                                  </span>
                                ))}

                              </small></>
                            </ul>

                          </td>

                          <td style={{ padding: '5px' }}>{data.quantity}</td>
                          <td style={{ padding: '5px' }}>£{(data.productPrice).toFixed(2)}</td>
                          <td style={{ padding: '5px' }}>£{(data.quantity * data.productPrice).toFixed(2)}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                <div className="row p-3" style={{ background: '#f0f0f0' }}>
                  <div className="col-6">
                    {ViewOrder && ViewOrder.productInfo && ViewOrder.productInfo[0] && ViewOrder.productInfo[0].distance && ViewOrder.productInfo[0].distance < 0.5 && ViewOrder?.productInfo[0].totalPrice > 20 ? (
                      <>
                        <p className="font-weight-bold">Subtotal: £{(ViewOrder && ViewOrder.productInfo && ViewOrder.productInfo[0] && ViewOrder.productInfo[0].totalPrice && ViewOrder.productInfo[0].totalPrice).toFixed(2)}</p>
                      </>
                    ) : (
                      <>
                        <p className="font-weight-bold">Delivery Charges: £2.49</p>
                        <p className="font-weight-bold">Subtotal: £{((ViewOrder && ViewOrder.productInfo && ViewOrder.productInfo[0] && ViewOrder.productInfo[0].totalPrice && (ViewOrder.productInfo[0].totalPrice).toFixed(2)))}</p>
                      </>
                    )}
                    <h5 className="font-weight-bold">Total: £{((ViewOrder && ViewOrder.productInfo && ViewOrder.productInfo[0] && ViewOrder.productInfo[0].totalPrice && ViewOrder.productInfo[0].totalPrice) + 2.49).toFixed(2)}</h5>
                  </div>
                  <div className="col-6"></div>
                </div>
              </div> 

            </div>
            <div class="modal-footer">
              <button type="button" style={{backgroundColor:'#e57300', height:"40px", width:"100px", fontSize:"20px"}} class="btn btn-secondary text-white" data-bs-dismiss="modal">Close</button>
              <FunctionalComponent2 commodityList={ViewOrder}/>
            </div>
          </div>
        </div>

        </div>
      {/* </div>  */}

    </div>
  );
}

export default HomePage;
