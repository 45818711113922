import * as React from "react";
import ReactToPrint from "react-to-print";

import { ComponentToPrint2 } from "./ComponentToPrint2";

const FunctionalComponent2 = ({ commodityList }) => {
    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {

        return (
            <button
                type="button"
                className="btn btn-success fs-3"
                style={{ textAlign: "center" }}
            >
                Print Receipt
            </button>
        );
    }, []);

    return (
        <div>
            <ReactToPrint
                content={reactToPrintContent}
                documentTitle="AwesomeFileName"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={reactToPrintTrigger}
            />
            {loading && <p className="indicator">Printing Receipt...</p>}
            <ComponentToPrint2
                commodityList={commodityList}
                ref={componentRef}
                text={text}
            />
        </div>
    );
};

export default FunctionalComponent2;
