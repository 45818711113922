import React, { useState } from "react";
import { apiUrl } from "../assets/utils/env";
import axios from "axios";

const options = {
  weekday: "short",
  day: "numeric",
  month: "short",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZone: "Europe/London",
};

function ProductReportRow({ row, index, subCategories }) {
  const [isEditing, setIsEditing] = useState(false);
  const [price, setPrice] = useState(row.totalPrice);
  const [quantity, setQuantity] = useState(row.totalSales);

  const [filteredSubCategory] = subCategories?.filter(
    (subCat) => subCat._id === row.subCategory
  );

  return (
    <tr style={{ textAlign: "center" }}>
      <td className="w-10px align-middle">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`product${index}`}
          />
          <label className="form-check-label"></label>
        </div>
      </td>
      <td className="align-middle">
        <a href>{row.name}</a>
      </td>
      <td className="align-middle">
        {row?.chosenOptions?.map((opt) => (
          <>
            <span>
              {opt.stepName}: {opt.type}
            </span>
            <br />
          </>
        ))}
      </td>
      <td className="align-middle">{filteredSubCategory.name}</td>
      <td
        className="align-middle"
        style={{ maxWidth: "200px", overflow: "hidden" }}
      >
        {isEditing ? (
          <input
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        ) : (
          price
        )}
      </td>
      <td
        className="align-middle"
        style={{ maxWidth: "200px", overflow: "hidden" }}
      >
        {isEditing ? (
          <input
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        ) : (
          quantity
        )}
      </td>
    </tr>
  );
}

export default ProductReportRow;
